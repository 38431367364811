import { template as template_8ea99402b8d74697a2f29dd3b62de53f } from "@ember/template-compiler";
const FKLabel = template_8ea99402b8d74697a2f29dd3b62de53f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
