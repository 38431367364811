import { template as template_1b70efc128574adb9aee85fc80efc3bd } from "@ember/template-compiler";
const WelcomeHeader = template_1b70efc128574adb9aee85fc80efc3bd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
